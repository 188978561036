import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    handleError(err: Error) {
        console.error('ErrorService.handleError: ', err);
        Sentry.captureException(err);
    }
}
